import React from 'react';
import { Firestore, collection, getDocs, query, where, Query } from 'firebase/firestore/lite';
import './Leaderboard.css';
import "./UserSearchView.css";
import "./LoadingSpinner.css";
import "./global.css";
import { UserRowView, UserRowModel } from './UserRowView.tsx';
import { LoadingSpinner } from './LoadingSpinner';

interface Props {
  firestore: Firestore;
}

export const UserSearchView: React.FC<Props> = (props) => {
  const [searchBodyMessage, setSearchBodyMessage] = React.useState("");
  const [usernameInput, setUsernameInput] = React.useState('');
  const [isSearching, setIsSearching] = React.useState(false);
  const [fetchedUsers, setFetchedUsers] = React.useState<UserRowModel[]>([]);

  const handleChange = (event) => {
    setUsernameInput(event.target.value);
  }

  const handleSubmit = (event) => {
    console.log("Handling submit");
    event.preventDefault();
    console.log("username input: ", usernameInput);
    if (usernameInput.length <= 0) {
      return;
    }
    searchForUser(usernameInput);
  }

  async function searchForUser(usernameInput: string) {
    setIsSearching(true);
    setSearchBodyMessage("");
    setFetchedUsers([]);
    const usersCollection = collection(props.firestore, "User-Profiles");
    const usernameQuery = query(usersCollection, where("username", "==", usernameInput));
    console.log(usernameQuery);
    let users = await fetchUsers(usernameQuery);
    if (users.length <= 0) {
      const displayNameQuery = query(usersCollection, where("displayName", "==", usernameInput));
      users = await fetchUsers(displayNameQuery);
    }
    if (users.length <= 0) {
      setSearchBodyMessage("Couldn't find user for " + usernameInput);
    } else {
      setFetchedUsers(users);
    }
    setIsSearching(false);
  }

  async function fetchUsers(userQuery: Query) {
    const querySnapshot = await getDocs(userQuery);
    const users: UserRowModel[] = [];
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      const userData = doc.data();
      const user = new UserRowModel(userData.displayName, userData.username, userData.points, userData.profilePicLink);
      users.push(user);
    });
    return users;
  }

  return (
    <div style= {{width: '100%', marginBottom: "10px"}}>
      <h1 className='cardHeading'>Check Your Score</h1>
      <div style={{ display: "flex", justifyContent: "center"}}>
        <div className="cardView">
          {fetchedUsers.map((userRowModel, i) => (
            <UserRowView key={i} userRowModel={userRowModel} />
          ))}
          {!!isSearching &&
            <LoadingSpinner />
          }
          {searchBodyMessage.length > 0 && <p>{searchBodyMessage}</p>}
          <form className="searchForm" onSubmit={handleSubmit}>
            <input type="search" value={usernameInput} placeholder="Search by username" onChange={handleChange}
              style={{flexGrow: "1", marginRight: "10px"}}
            />
            <button className="searchButton" type="submit" style={{borderRadius: "18px"}}>Search</button>
          </form>
        </div>
      </div>
    </div>
  );
};