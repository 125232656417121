import React, { useState, useEffect } from 'react'
// import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import './NavigationBar.css';

const NavigationBar = () => {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 20) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const navbarClass = scrolled ? 'navbar-custom scrolled' : 'navbar-custom';

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Navbar className={`mb-3 ${navbarClass}`} sticky="top">
      <Container>
        <Navbar.Brand href="#home">
          <img
            src="/NavBarLogo.png"
            className="d-inline-block align-middle"
            alt="Anything Goes"
            style={{maxWidth: "200px", aspectRatio: "auto"}}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto align-items-center">
            {/* <Nav.Link href="#" className="d-flex">What is this?</Nav.Link> */}
            <Nav.Link href="https://www.youtube.com/justinlennox" target="_blank">
              <img
                src="/youtubeLogo.svg"
                alt="YouTube"
                style={{maxWidth: "27px", aspectRatio: "auto", position: "relative", top: "2px"}}
              />
            </Nav.Link>
            <Nav.Link href="https://www.instagram.com/justinlennx/" style={{width: "40px", height: "40px"}}>
              <img
                src="/instagramLogo.svg"
                alt="Instagram"
                style={{maxHeight: "25px", aspectRatio: "auto"}}
              />
            </Nav.Link>
            <Nav.Link href="https://www.tiktok.com/@justinlennx" target="_blank" style={{width: "40px", height: "40px"}}>
              <img
                src="/tiktokLogo.svg"
                alt="TikTok"
                style={{maxHeight: "23px", aspectRatio: "auto"}}
              />
            </Nav.Link>
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">
              Another action
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">
              Separated link
            </NavDropdown.Item>
          </NavDropdown> */}
            {/* <Button variant="outline-success">Search</Button> */}
        </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;