import React, { useEffect, useState } from 'react'
import './App.css';
import { Leaderboard, LeaderboardModel } from './Leaderboard.tsx';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore, getDoc, doc } from 'firebase/firestore/lite';
import { UserSearchView } from './UserSearchView.tsx';
import { getAuth, signInAnonymously } from "firebase/auth";
import NavigationBar from './NavigationBar.tsx';
import Footer from "./Footer.tsx";
import { LoadingSpinner } from './LoadingSpinner';

const firebaseConfig = {
  apiKey: "AIzaSyCI8_trEkTAUq_JJtCnOvBonEpzVztKMU4",
  authDomain: "anything-goes-af5a4.firebaseapp.com",
  databaseURL: "https://anything-goes-af5a4-default-rtdb.firebaseio.com",
  projectId: "anything-goes-af5a4",
  storageBucket: "anything-goes-af5a4.appspot.com",
  messagingSenderId: "778729803364",
  appId: "1:778729803364:web:870c046a60c3245f5f5971",
  measurementId: "G-S1PLFG6HLY"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth();
const analytics = getAnalytics(firebaseApp);
const firestore = getFirestore(firebaseApp);

logEvent(analytics, 'screen_view', {
  firebase_screen: "Main App", 
  firebase_screen_class: "Main App"
});

function App() {
  const [errorMessage, setErrorMessage] = useState(null);
  const [leaderboards, setLeaderboards] = useState(null);
  const [nextShowDate, setNextShowDate] = useState(null);
  const [nextShowDateSubtitle, setNextShowDateSubtitle] = useState(null);
  
  useEffect(() => {
    signInAnonymously(firebaseAuth)
    .then(() => {
      getDailyContent().then((snapshot) => {
        console.log("Done!");
      });
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      logEvent(analytics, 'web_error', {
        error: errorMessage, 
        errorCode: errorCode
      });
      setErrorMessage(errorMessage);
    });
  }, []);

  async function getDailyContent() {
    const dailyContentRef = doc(firestore, "Leaderboards", "MainLeaderboards");
    const dailyContentSnap = await getDoc(dailyContentRef);
    
    if (dailyContentSnap.exists()) {
      const data = dailyContentSnap.data();
      if (data) {
        const nextShowDateRaw = data.nextShowDate.toDate();
        setFormattedShowDate(nextShowDateRaw);
        const leaderboardsData = [
          data.todaysTopScorers, 
          data.todaysTopGifters, 
          data.allTimeTopScorers, 
          data.allTimeTopGifters
        ];
        console.log(leaderboardsData);
        const leaderboards = leaderboardsData
        .filter(leaderboard => {
          return !!leaderboard;
        })
        .map((leaderboardData, _) => (
          new LeaderboardModel(leaderboardData.title, leaderboardData.rows)
        ));
        setLeaderboards(leaderboards);
      }
    } else {
      console.log("No leaderboard data!");
    }
  }

  const setFormattedShowDate = (date) => {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const isToday = date.toDateString() === now.toDateString();
    const isTomorrow = date.toDateString() === tomorrow.toDateString();

    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    const monthOptions = { month: 'long' };
    const day = date.getDate();

    const timeString = new Intl.DateTimeFormat('default', timeOptions).format(date);
    const monthString = new Intl.DateTimeFormat('default', monthOptions).format(date);
    const dayString = `${day}${getOrdinalSuffix(day)}`;
    const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneString = formatTimezone(timezone);
  
    if (isToday) {
      setNextShowDate(`Today, ${timeString}`);
      setNextShowDateSubtitle(`${monthString} ${dayString} (${timezoneString})`);
    } else if (isTomorrow) {
      setNextShowDate(`Tomorrow, ${timeString}`);
      setNextShowDateSubtitle(`${monthString} ${dayString} (${timezoneString})`);
    } else {
      setNextShowDate(`${monthString} ${dayString}, ${timeString}`);
      setNextShowDateSubtitle(`(${timezoneString})`)
    }
  }

  function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
        case 1:  return 'st';
        case 2:  return 'nd';
        case 3:  return 'rd';
        default: return 'th';
    }
  }

  function formatTimezone(timezone) {
    return timezone
        .split('/')
        .map(part => 
            part
            .split('_')
            .map(word => word[0].toUpperCase() + word.substr(1).toLowerCase())
            .join(' ')
        )
        .join(' - ');
}

  return (
    <div className="App">
      <div className="appBody">
        <NavigationBar />

        {/* Title */}
        <div style={{display: "flex", justifyContent: "center", paddingTop: "2rem"}}>
          <img src="/NavBarLogo.png" style={{width: "90%", maxWidth: "400px", aspectRatio: "auto"}} alt="Anything Goes"/>
        </div>
        <div style={{display: "flex", justifyContent: "center", paddingBottom: "10px"}}>
          <p style={{color: "rgb(172, 132, 197)", padding: "5px", fontWeight: "900", textAlign:"center"}}>Daily trivia, games, and more 
          <br /> Scores and leaderboards updated at the end of each show</p>
        </div>

        {/* Error */}
        {!!errorMessage && 
          (<div style={{display: "flex", justifyContent: "center", textAlign:"center"}}>
            <div className='cardView' style={{background:"red", fontWeight:"bold", color:"white"}}>{errorMessage}</div>
          </div>)
        }

        {/* Next Show Date */}
        <div style={{display: "flex", justifyContent: "center", paddingBottom: "30px", textAlign: "center", color: "white"}}>
          <div style={{backgroundColor: "var(--accent-pink)", borderRadius: "30px", padding: "20px 30px"}}>
            <p style={{fontWeight: "700", margin: "0"}}>Next Game</p>
              {nextShowDate ? (
                <div>
                  <p style={{fontWeight: "900", margin: "0", fontSize: "20px"}}>{nextShowDate}</p>
                  <p style={{fontWeight: "700", margin: "0", opacity: "0.7"}}>{nextShowDateSubtitle}</p>
                </div>
              )
              : <LoadingSpinner />
              }
          </div> 
        </div>

        <UserSearchView firestore={firestore} />

        {/* Leaderboards */}
        {(leaderboards && leaderboards.length > 0) ? 
          (leaderboards ?? []).map((leaderboardModel, i) => (
                <Leaderboard key={i} leaderboardModel={ leaderboardModel } />
          ))
          :
          <LoadingSpinner />
        }
      </div>

      <Footer />
    </div>
  );
}

export default App;
