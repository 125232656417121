import React from 'react';

const backgroundGradients = [
	"linear-gradient(to left bottom, rgb(238, 41, 120), rgb(238, 85, 154))",
	"linear-gradient(to left bottom, rgb(100, 220, 208), rgb(83, 194, 220))",
	"linear-gradient(to left bottom, rgb(251, 214, 106), rgb(247, 152, 33))",	
	"linear-gradient(to left bottom, rgb(200, 109, 251), rgb(102, 47, 229))",	
];

export class UserRowModel {
  displayName: string;
  username: string;
  points?: number;
  profilePictureURL?: string;

  constructor(displayName: string, username: string, points?: number, profilePictureURL?: string) {
    this.displayName = displayName
    this.username = username
    this.points = points
    this.profilePictureURL = profilePictureURL
	}
}

interface Props {
	userRowModel: UserRowModel;
	index?: number;
}

export const UserRowView: React.FC<Props> = (props) => {
	const [isValidImage, setIsValidImage] = React.useState(true);

	const errorImage = () => {
		console.log("error");
		setIsValidImage(false);
	}

  return (
	<div style={{ display: 'flex', paddingBottom: '15px', alignItems: 'center', gap: "10px" }}>
	{(props.index != null) && <div style={{color: "gray", fontWeight: "900", fontSize: "12px"}}>{props.index < 9 ? "  " : ""}{props.index + 1}</div> }
	<div style={{width: "50px", height: "50px", borderRadius: "20px", background: backgroundGradients[Math.floor(Math.random()*backgroundGradients.length)], overflow: "hidden"}}>
		{(props.userRowModel.profilePictureURL && isValidImage) && 
			<img src={props.userRowModel.profilePictureURL}
			alt={`${props.userRowModel.displayName}`} onError={errorImage}
			style={{width: "100%", height: "100%", aspectRatio:"auto"}}
			/>
		}
	</div>
	{/* Name Display */}
	<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
		<div style={{ fontSize: '16px', fontWeight: '900' }}>{props.userRowModel.displayName}</div>
		<div style={{ fontSize: '13px', color: "gray" }}>@{props.userRowModel.username}</div>
	</div>
	<div style={{ flex: 1 }} />
		{ props.userRowModel.points && 
			<div style={{ fontSize: '12px', color: "gray", border: "1px solid rgb(218, 218, 218)", padding: "10px", borderRadius: "20px"}}>
			{(props.userRowModel.points ?? "")}pts
			</div>
		}
	</div>
  );
};