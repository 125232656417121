import React from "react";

const Footer = () => {
	return (
		<div style={{ background: "rgb(17, 11, 45)", fontSize: "12px"}}>
		<footer className="d-flex flex-wrap justify-content-between align-items-center py-3">
		  <div className="col-md-4 d-flex align-items-center">
			<a href="/" className="mb-3 me-2 mb-md-0 text-decoration-none lh-1">
			  <svg className="bi" width="30" height="24"><use xlinkHref="#bootstrap"></use></svg>
			</a>
			<span className="mb-3 mb-md-0 text-muted">© 2023 Foxtail Games LLC. All rights reserved.</span>
		  </div>
	  
		  {/* <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
			<li className="ms-3"><a className="text-muted" href="#"><svg className="bi" width="24" height="24"><use xlinkHref="#twitter"></use></svg></a></li>
			<li className="ms-3"><a className="text-muted" href="#"><svg className="bi" width="24" height="24"><use xlinkHref="#instagram"></use></svg></a></li>
			<li className="ms-3"><a className="text-muted" href="#"><svg className="bi" width="24" height="24"><use xlinkHref="#facebook"></use></svg></a></li>
		  </ul> */}
		</footer>
	  </div>
	)
}

export default Footer;
